import React from 'react'
import { Routes, Route } from 'react-router-dom'
import About from '../components/About'
import Cv from '../components/Cv'
import Home from '../components/Home'

const AppRoutes: React.FC = () => (
    <Routes>
        <Route path="/cv" element={<Cv />} />
        <Route path="/about" element={<About />} />
        <Route path="/home" element={<Home />} />
        <Route path="/" element={<Home />} />
    </Routes>
)

export default AppRoutes
