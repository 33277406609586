import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AppRoutes from '../routes/Routes'

const App: React.FC = () => {
    const [state] = useState<Array<String>>(['Home', 'CV', 'About'])

    const renderNav = () =>
        state.map((s) => (
            <Link
                key={s as React.Key}
                to={s.toLocaleLowerCase()}
                style={{ margin: 10 }}
            >
                {s}
            </Link>
        ))

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <h1>JRGN</h1>
            <div>{renderNav()}</div>
            <AppRoutes />
        </div>
    )
}

export default App
